import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`20 KB SDHP’s (53/35)`}</p>
    <p>{`20 T2B`}</p>
    <p>{`200Ft Shuttle Run (50ft x 4)`}</p>
    <p><strong parentName="p">{`*`}{`This Friday is “First Friday Hop” around Nulu.  We’re offering any
of our normally scheduled classes for free so invite a friend!`}</strong></p>
    <p><strong parentName="p">{`*`}{`This Saturday is Derby day and it’s also our monthly free workout at
9:00 & 10:00am so you can bring a friend this day as well!  We’ll have
just one hour of open gym on Derby day so we’ll close at 12:00.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      